<template>
    <div class="predictBox">
        <el-form-item label="选择赛事">
            <el-select style="width: 100%" v-model="match_id" filterable placeholder="请选择">
                <el-option
                v-for="item in data"
                :key="item.match_id"
                :label="item.match_text"
                :value="item.match_id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="">
            <div class="predictBox">
                <div v-if="odds" class="item">
                    <p
                        @click="itemClick(odds.type_id, '1', odds.current_right)"
                        class="predict"
                        :class="{'active':predict_result == '1'}"
                    >
                        <em>{{odds.current_left}}</em>
                        <span>{{ odds.type_id == 1?'主胜':'大球' }}</span>
                    </p>
                    <p>
                        <em>{{odds.ovalue}}</em>
                        <span>{{ odds.type_id == 1?'让球':'大小' }}</span>
                    </p>
                    <p
                        @click="itemClick(odds.type_id, '2', odds.current_left)"
                        class="predict"
                        :class="{'active':predict_result == 2}"
                    >
                        <em>{{odds.current_right}}</em>
                        <span>{{ odds.type_id == 1?'客胜':'小球' }}</span>
                    </p>
                </div>
            </div>
        </el-form-item>
    </div>
</template>

<script>
export default {
    name: 'predictItem',
    props: ['data'],
    data () {
        return {
            match_id: '',
            odds: null,
            play_type: '',
            predict_result: '',
            first_odds: ''
        }
    },
    watch: {
        match_id () {
            this.reset();
            this.odds = [];
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].match_id === this.match_id) {
                    this.odds = this.data[i].odds_info
                }
            }
        }
    },
    methods: {
        reset () {
            this.play_type = '';
            this.predict_result = '';
            this.first_odds = '';
        },
        // TODO reset
        itemClick (type, res, odds) {
            this.play_type = type;
            this.predict_result = res;
            this.first_odds = odds
            this.$emit('setRes', {
                match_id: this.match_id,
                play_type: this.play_type,
                predict_result: this.predict_result,
                firstOdds: this.first_odds,
                odds_info: this.odds
            })
        }
    }
}
</script>

<style lang="less" scoped>
.predictBox{
    .predictBox{
        border: 1px solid #fff;
        display: flex;
        flex-direction: column-reverse;
        .item{
            width: 100%;
            height: 40px;
            background-color: #f2f2f2;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #fff;
            p{
                width: 33.33%;
                text-align: center;
                border-left: 1px solid #fff;
                font-weight: bold;
            }
            p.predict{
                cursor: pointer;
                &:hover{
                    background-color: rgb(245, 92, 92);
                }
            }
            .active{
                background-color: red;
                color: #fff;
            }
        }
    }
}
</style>
