<template>
    <div class="profitPlan">
        <el-form label-position="right" label-width="80px">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="专家名称">
                        <el-select filterable v-model="hiddenUid" placeholder="请选择">
                            <el-option
                            v-for="item in expertsOptions"
                            :key="item.eid"
                            :label="item.nick_name"
                            :value="item.eid + '&' + item.period_id + '&' + item.period_no + '&' + item.num">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="期号">
                        <el-input disabled v-model="period_no"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="场次">
                        <el-input disabled v-model="phase"></el-input>
                    </el-form-item>
                </el-col>
                <el-col>
                    <el-form-item label="玩法">
                        <el-radio v-model="play_type" label="1">让球</el-radio>
                        <el-radio v-model="play_type" label="3">大小</el-radio>
                    </el-form-item>
                </el-col>
            </el-row>
            <predicItem ref="firstMatchs" :data="matchList" @setRes="setFirstRes"></predicItem>
        </el-form>
        <div class="footer">
            <span @click="reset">取消</span>
            <span @click="save">确定</span>
        </div>
        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>当前场次已有方案，是否继续添加？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import api from '../../../service/api';
import predicItem from "../../../components/three/predictItem.vue";
export default {
    name: 'profitPlan',
    components: {
        predicItem
    },
    data () {
        return {
            dialogVisible: false,
            matchList: [],
            expertsOptions: [],
            hiddenUid: '',
            play_type: '1',
            uid: '', // 专家
            period_id: '',
            period_no: '', // 期次
            phase: '', // 场次
            firstMatch: {}
        }
    },
    computed: {
    },
    created () {
        this.initExpertsList()
    },
    watch: {
        play_type () {
            this.reset();
            this.initMatchList()
        },
        hiddenUid () {
            const arr = this.hiddenUid.split('&');
            this.uid = arr[0];
            this.period_id = arr[1];
            this.period_no = arr[2];
            this.phase = arr[3];
            this.initMatchList()
        }
    },
    methods: {
        // 保存 TODO 校验参数
        async save () {
            this.dialogVisible = false;
            if (!this.firstMatch.play_type) {
                this.$message.error('请选择比赛！')
                return
            }
            const param = {
                eid: this.uid,
                period_id: this.period_id,
                match_id: this.firstMatch.match_id,
                predict_result: this.firstMatch.predict_result,
                odds_info: this.firstMatch.odds_info
            }
            const res = await api.profitPlanPublish3profit(param)
            if (res.code === 0) {
                this.$message({
                    type: 'sucess',
                    message: '添加成功！'
                })
                window.parent.location.href = 'https://dpi.zjqvv.com/op-admin/three-profit/period'
            } else {
                alert(res.msg)
            }
        },
        // 重置状态
        reset () {
            this.firstMatch = {};
            this.$refs.firstMatchs.match_id = '';
        },
        setFirstRes (obj) {
            this.firstMatch = obj
        },
        // 初始化比赛列表
        async initMatchList () {
            this.reset();
            const res = await api.initProfitMatchList3profit({
                play_type: this.play_type
            })
            if (res.code === 0) {
                this.matchList = res.data || []
            } else {
                this.matchList = []
                alert(res.msg)
            }
        },
        async initExpertsList () {
            const res = await api.getExpertsList3profit()
            if (res.code === 0) {
                this.expertsOptions = res.data;
            } else {
                alert(res.msg)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.profitPlan{
    padding: 20px;
    text-align: left;
    .return{
        text-align: center;
        font-size: 14px;
        color: red;
        margin-top: 50px;
    }
    .footer{
        width: 100%;
        margin-top: 40px;
        text-align: center;
        span{
            display: inline-block;
            margin-right: 20px;
            width: 120px;
            height: 40px;
            line-height: 40px;
            border-radius: 8px;
            background-color: #eee;
            cursor: pointer;
        }
    }
}
</style>
